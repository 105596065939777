import _ from 'lodash';

const WEB_INTEL_URL = 'https://twitter.com/intent/tweet?';

interface ShareToTwitterParameters {
  text: string;
  url?: string;
  hashtags?: string;
  via?: string;
}

export const shareToTwitter = async (parameters: ShareToTwitterParameters) => {
  const params = _.map(
    parameters,
    (value = '', key) => `${key}=${encodeURIComponent(value)}`
  );

  const url = WEB_INTEL_URL + params.join('&');

  // ✅Open the URL in X App
  // can't use window.open because of Metamask's popup blockers
  const a = document.createElement('a');
  a.href = url;
  a.target = '_blank';
  document.body.appendChild(a);
  a.click();
  a.remove();
};
