import { Select } from 'antd';
import { BigNumber, Contract, ethers } from 'ethers';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useAccount, useBalance, useBlockNumber, useSwitchChain } from 'wagmi';
import { formatUnits } from 'ethers/lib/utils';
import { bsc, bscTestnet, mainnet, sepolia } from 'viem/chains';
import Decimal from 'decimal.js';
import { useQueryClient } from '@tanstack/react-query';
import { erc20Abi } from 'viem';

import { cleanNumString, roundDownNumString } from '@/utils/formatNumber';
import config from '@/config';
import LoadingIcon from '@/components/Loading';
import mTokenAbi from '@/abis/MToken.json';
import useUsdPrices from '@/store/useUsdPrices';
import { StakeChain, StakeMToken, StakeToken } from '..';

export type StakeFromProps = {
  selectedChain: StakeChain | null;
  setSelectedChain: (selectedChain: StakeChain) => void;
  amount: string;
  setAmount: (amount: string) => void;
  managerContract: Contract | null;
  isMantaPacificChain: boolean;
  balance: string;
  setBalance: (balance: string) => void;
  selectedToken: StakeToken | null;
  setSelectedToken: (selectedToken: StakeToken | null) => void;
  layerZeroGasFee: BigNumber;
  estimatedGasFee: BigNumber;
};

const { MANTA_PACIFIC_CHAIN, IS_TESTNET, mETH, mUSD, mBTC } = config;

const supportedMTokenMap: Record<
  string,
  { address: string; decimals: number; name: string }
> = {
  USDC: mUSD,
  USDT: mUSD,
  wUSDM: mUSD,
  ETH: mETH,
  STONE: mETH,
  WBTC: mBTC,
  BTCB: mBTC,
  wBTC: mBTC
};

const chainOptions = config.supportedChain.map(chain => ({
  ...chain,
  value: chain.id,
  label: chain.name
})) as StakeChain[];

export const StakeFrom = ({
  amount,
  setAmount,
  selectedChain,
  setSelectedChain,
  managerContract,
  isMantaPacificChain,
  balance,
  setBalance,
  selectedToken,
  setSelectedToken,
  layerZeroGasFee,
  estimatedGasFee
}: StakeFromProps) => {
  const [queryBalanceLoading, setQueryBalanceLoading] = useState(false);
  const [tokenList, setTokenList] = useState<StakeToken[]>([]);
  const [tokenListLoading, setTokenListLoading] = useState(false);

  const { switchChainAsync } = useSwitchChain();
  const { address, chain } = useAccount();
  const { data: blockNumber } = useBlockNumber({ watch: true });
  const { data: nativeBalance, queryKey } = useBalance({
    address
  });
  const queryClient = useQueryClient();
  // query user's balance every 5 blocks
  useEffect(() => {
    if (!blockNumber) {
      return;
    }
    if (Number(blockNumber) % 5 === 0)
      queryClient.invalidateQueries({ queryKey });
  }, [blockNumber, queryClient]);

  const usdPrices = useUsdPrices();

  const isWrongNetwork = useMemo(
    () => chain?.id !== selectedChain?.id,
    [selectedChain, chain]
  );
  const isBscChain = useMemo(() => {
    return selectedChain?.id === (IS_TESTNET ? bscTestnet.id : bsc.id);
  }, [selectedChain]);
  const isEthereumChain = useMemo(() => {
    return selectedChain?.id === (IS_TESTNET ? sepolia.id : mainnet.id);
  }, [selectedChain]);

  useEffect(() => {
    const curChain = chainOptions.find(chainItem => chain?.id === chainItem.id);
    if (curChain) {
      setSelectedChain(curChain);
    } else {
      setSelectedChain(chainOptions[0]!);
    }
  }, [chain, setSelectedChain]);

  useEffect(() => {
    if (isWrongNetwork) {
      setBalance('0');
      return;
    }
    const getBalance = async () => {
      if (!selectedToken) {
        return;
      }
      try {
        if (selectedToken?.isNativeToken) {
          nativeBalance?.formatted !== undefined &&
            setBalance(roundDownNumString(nativeBalance?.formatted));
        } else {
          setQueryBalanceLoading(true);
          const tokenContract = selectedToken?.tokenContract;
          const tokenBalance = await tokenContract?.balanceOf(address);

          const finalBalance = tokenBalance
            ? formatUnits(tokenBalance as BigNumber, selectedToken?.decimals)
            : '0';

          setBalance(roundDownNumString(finalBalance, 5));
        }
      } catch (e) {
        console.log('get token balance error', e);
      } finally {
        setQueryBalanceLoading(false);
      }
    };
    getBalance();
  }, [
    address,
    isWrongNetwork,
    nativeBalance?.formatted,
    selectedToken,
    setBalance
  ]);

  useEffect(() => {
    const getTokenList = async () => {
      if (!managerContract) {
        return [];
      }
      setTokenListLoading(true);

      const contractTokenList = await managerContract.getAllAcceptTokens(); // ManagerAdapter | DepositManager

      const tokenList: StakeToken[] = [];
      for (let i = 0; i < contractTokenList.length; i++) {
        const token = contractTokenList[i];
        if (token?.stakePaused || token?.depositPaused) {
          continue;
        }

        const tokenAddress = token?.token ?? token?.stakeToken;

        const isNativeToken = tokenAddress === ethers.constants.AddressZero;
        // set bsc native token name to bnb
        // set manta/ethereum native token name to eth
        let tokenName = token?.symbol;
        if (isNativeToken) {
          if (isMantaPacificChain || isEthereumChain) {
            tokenName = 'ETH';
          } else if (isBscChain) {
            tokenName = 'BNB';
          }
        }

        const mTokenInfo = supportedMTokenMap[tokenName as any] ?? {
          address: '',
          decimals: 0,
          name: ''
        };

        const mTokenContract = new Contract(
          mTokenInfo?.address,
          mTokenAbi,
          new ethers.providers.StaticJsonRpcProvider(
            MANTA_PACIFIC_CHAIN?.rpcUrls?.default.http[0]
          )
        );

        const mToken: StakeMToken = {
          address: mTokenInfo?.address,
          name: mTokenInfo?.name,
          decimals: mTokenInfo?.decimals,
          tokenContract: mTokenContract
        };

        const minDepositAmount = token?.minDepositAmount;

        const finalToken = {
          mToken,
          decimals: token?.decimals ?? token?.stakeTokenDecimals,
          stakePaused: token?.stakePaused ?? token?.depositPaused,
          minDepositAmount,
          value: tokenName,
          label: tokenName,
          name: tokenName,
          isNativeToken,
          address: tokenAddress,
          tokenContract: new Contract(
            tokenAddress,
            erc20Abi,
            managerContract.provider
          ),
          fee: token?.fee,
          conversionRate: token?.conversionRate
        };

        tokenList.push(finalToken);
      }
      if (isMantaPacificChain) {
        tokenList.reverse(); // set the wUSDM to first
      }
      setTokenList(tokenList);
      setSelectedToken(tokenList[0] ?? null);
      setTokenListLoading(false);
    };
    getTokenList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [managerContract, isMantaPacificChain]);

  const handleSelectChain = async (value: number) => {
    const curSelectedChain = chainOptions.find(chain => value === chain.id);
    if (curSelectedChain) {
      try {
        await switchChainAsync?.({ chainId: curSelectedChain?.id });
        setSelectedChain(curSelectedChain);
      } catch (e) {
        console.log('switch network error', e);
      }
    }
  };

  const handleTokenChange = (value: string) => {
    const token = tokenList?.find(token => token.value === value);
    setSelectedToken(token ?? null);
  };

  const handleAmountChange = (e: ChangeEvent<HTMLInputElement>) => {
    setAmount(cleanNumString(e?.target?.value?.trim(), 5));
  };

  const handleMax = () => {
    if (selectedToken?.isNativeToken) {
      let maxBalance = BigNumber.from(nativeBalance?.value).sub(
        estimatedGasFee
      );

      if (!isMantaPacificChain) {
        maxBalance = maxBalance.sub(layerZeroGasFee);
      }

      if (maxBalance.lt(BigNumber.from('0'))) {
        maxBalance = BigNumber.from('0');
      }

      setAmount(
        roundDownNumString(
          formatUnits(maxBalance.toBigInt(), selectedToken?.decimals)
        )
      );
    } else {
      setAmount(balance);
    }
  };

  const displayBalance = () => {
    const decimalBalance = new Decimal(balance || '0');
    if (decimalBalance.gt(new Decimal(Math.pow(10, 14)))) {
      return decimalBalance.toExponential(5, Decimal.ROUND_HALF_DOWN);
    }
    return balance;
  };

  return (
    <>
      <div className="mb-2 flex items-center">
        <span className="font-medium">From:</span>
        <Select
          options={chainOptions}
          defaultValue={chainOptions[0]?.value}
          className="stake-select ml-2 min-w-[200px] stake-select-chain"
          popupClassName="stake-select-menu chain-select-menu"
          labelRender={(props: any) => (
            <div className="text-primary-black/80 text-left text-base overflow-hidden text-ellipsis whitespace-nowrap">
              {props.label}
            </div>
          )}
          value={selectedChain?.value}
          onChange={handleSelectChain}
        />
      </div>
      <div className="flex flex-col mb-4 border border-green/40 p-4 rounded-lg text-primary-black">
        <div className="flex items-center mb-1">
          <input
            placeholder="0"
            className="flex-1 font-medium text-xl max-md:min-w-[20px]"
            onChange={handleAmountChange}
            value={amount}
          />
          {tokenListLoading ? (
            <LoadingIcon isDark />
          ) : (
            <Select
              options={tokenList}
              className="stake-select w-[155px] max-md:w-[120px]"
              value={selectedToken?.value}
              onChange={handleTokenChange}
              popupClassName="stake-select-menu"
            />
          )}
        </div>
        <div className="flex items-center text-sm max-md:justify-end">
          {selectedToken?.name && (
            <span className="mr-auto max-md:hidden">
              $
              {((usdPrices[selectedToken.name] ?? 0) * Number(amount)).toFixed(
                5
              )}
            </span>
          )}

          <span>
            <span className="font-medium">Balance: </span>
            <span className="break-all">
              {queryBalanceLoading ? '--' : displayBalance()}
            </span>
          </span>
          <button className="ml-2 text-green font-medium" onClick={handleMax}>
            Max
          </button>
        </div>
      </div>
    </>
  );
};
