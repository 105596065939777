import numbro from 'numbro';
import Marquee from 'react-fast-marquee';
import { useResponsive } from '@/hooks/useResonposive';

import btcCoin from '@/assets/svgs/btc-coin.svg';
import ethCoin from '@/assets/svgs/eth-coin.svg';
import usdtCoin from '@/assets/svgs/usdt-coin.svg';
import discordIcon from '@/assets/svgs/discord.svg';
import mediumIcon from '@/assets/svgs/medium.svg';
import xIcon from '@/assets/svgs/x.svg';
import useTVL from '@/store/useTVL';
import { DECIMAL_PLACES } from '@/store/useBatchMtoken';

interface CoinInfoProps {
  coinImg: string;
  coinName: string;
  value: string;
  apy: string;
}

const mediaLinks = [
  {
    icon: discordIcon,
    link: 'https://discord.com/invite/mantanetwork'
  },
  {
    icon: xIcon,
    link: 'https://twitter.com/MantaNetwork'
  },
  {
    icon: mediumIcon,
    link: 'https://mantanetwork.medium.com/'
  }
];

function CoinInfo({ coinImg, coinName, value, apy }: CoinInfoProps) {
  return (
    <div className="flex flex-shrink-0 flex-row items-center px-4">
      <img src={coinImg} alt={coinName} className="w-4 h-4 rounded-full" />
      <span className="text-[14px] text-black-title/60 ml-1">{coinName}</span>
      <span className="text-[14px] text-black-title ml-1  max-md:font-[600]">
        ${value}
      </span>
      <span className="text-[14px] text-black-title/60 ml-2">
        APR(variable)
      </span>
      <span className="text-[14px] text-black-title ml-1  max-md:font-[600]">
        {apy}
      </span>
    </div>
  );
}

function Divider() {
  return (
    <div className="flex-shrink-0 w-[1px] h-[13px] text-black-title bg-black-title"></div>
  );
}

function Banner() {
  const { isMobile } = useResponsive();

  const tvlStatus = useTVL();
  const { data: tvlData, isLoading } = tvlStatus;

  return (
    <div className="border-t border-b border-green/60 max-md:w-[100vw] max-md:overflow-hidden">
      <Marquee play={isMobile} className="banner-marquee">
        <div className="flex flex-row flex-shrink-0 justify-between items-center py-2 px-12 max-md:justify-start ">
          <div className="flex items-center">
            <div className="flex flex-shrink-0 gap-1 items-center pr-4">
              <span className="text-[14px] font-[400] text-black-title/60">
                TVL
              </span>
              <span className="text-[14px] font-[400] text-black-title max-md:font-[600]">
                ${' '}
                {!isLoading
                  ? tvlData?.tvl
                    ? numbro(tvlData?.tvl ?? 0).format({
                        thousandSeparated: true,
                        mantissa: DECIMAL_PLACES,
                        trimMantissa: true
                      })
                    : '0'
                  : '--'}
              </span>
            </div>
            <Divider />
            <CoinInfo
              coinImg={btcCoin}
              coinName="BTC"
              value={
                !isLoading
                  ? tvlData?.tokenList?.BTC?.tvl
                    ? numbro(tvlData?.tokenList?.BTC?.tvl ?? 0).format({
                        thousandSeparated: true,
                        mantissa: DECIMAL_PLACES,
                        trimMantissa: true
                      })
                    : '0'
                  : '--'
              }
              apy={!isLoading ? tvlData?.tokenList?.BTC?.apr || '0' : '--'}
            />
            <Divider />
            <CoinInfo
              coinImg={ethCoin}
              coinName="ETH"
              value={
                !isLoading
                  ? tvlData?.tokenList?.ETH?.tvl
                    ? numbro(tvlData?.tokenList?.ETH?.tvl ?? 0).format({
                        thousandSeparated: true,
                        mantissa: DECIMAL_PLACES,
                        trimMantissa: true
                      })
                    : '0'
                  : '--'
              }
              apy={!isLoading ? tvlData?.tokenList?.ETH?.apr || '0' : '--'}
            />
            <Divider />
            <CoinInfo
              coinImg={usdtCoin}
              coinName="USDT/USDC/wUSDM"
              value={
                !isLoading
                  ? tvlData?.tokenList?.USD?.tvl
                    ? numbro(tvlData?.tokenList?.USD?.tvl ?? 0).format({
                        thousandSeparated: true,
                        mantissa: DECIMAL_PLACES,
                        trimMantissa: true
                      })
                    : '0'
                  : '--'
              }
              apy={!isLoading ? tvlData?.tokenList?.USD?.apr || '0' : '--'}
            />
          </div>
          <div className="flex flex-shrink-0 gap-2 max-md:pl-16">
            {mediaLinks.map(({ icon, link }) => (
              <a
                key={link}
                href={link}
                target="_blank"
                rel="noreferrer"
                className="hover:opacity-80 "
                // className="flex items-center gap-4"
              >
                <img src={icon} alt={link} className="w-6 h-6" />
              </a>
            ))}
          </div>
        </div>
      </Marquee>
    </div>
  );
}
export default Banner;
