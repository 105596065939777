import classNames from 'classnames';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Popover } from 'antd';
import { useEffect, useMemo } from 'react';
import { BigNumber, ethers } from 'ethers';
import IBignumber from 'bignumber.js';
import useBatchMtokenInfo, { DECIMAL_PLACES } from '@/store/useBatchMtoken';
import { usePointsStatus } from '@/store/usePointsStatus';

import infoIcon from '@/assets/svgs/info-gray.svg';
import arrowBlack from '@/assets/svgs/arrow-black.svg';
import metamaskIcon from '@/assets/imgs/metamask.png';
import useUserInfo from '@/store/useUserInfo';
import { useAddToken } from '@/hooks/useAddToken';
import config from '@/config';
import { useUserMTokenBalance } from '@/store/useUserMTokenBalance';
import useUsdPrices from '@/store/useUsdPrices';
import { fromWei, toWei, numbroSafe } from '@/utils/format';
import { useEarnMoreStatus } from '@/store/useEarnMoreInfo';
import useGetMtokenStake from '@/store/useGetMTokenStake';
import UserAgreement from '../UserAgreement';
import NewButton from '../NewButton';
import MtokenDetail from '../MtokenDetail';

const { MANTA_PACIFIC_CHAIN, mBTC, mETH, mUSD } = config;

IBignumber.config({ EXPONENTIAL_AT: 99 });

function Dot() {
  return (
    <span className="inline-block w-[10px] h-[10px] bg-green rounded-md"></span>
  );
}

export const MyPoint = () => {
  const { userInfo } = useUserInfo();
  const { user_address } = userInfo;
  const { pointStatus } = usePointsStatus();
  const pointsData = pointStatus?.data;
  const showData = user_address && pointsData && pointsData?.code !== -2;

  return (
    <div className="flex flex-col w-[260px] rounded-tr-2xl rounded-br-2xl bg-white/80  pl-12 pr-6 py-6 border border-[#05D9A6]/60 text-black-title text-[20px] font-[400] max-md:flex-col max-md:items-center max-md:mt-2 max-md:px-6 max-md:py-4 max-md:w-full max-md:text-[14px] max-md:rounded-lg ">
      <p>Third Round Points</p>
      <div className="flex items-start gap-2 relative md:hidden">
        <div className="gradient-text-1 text-[14px] font-[500] italic">
          Current APR: 1%
        </div>
        <Popover
          overlayClassName="point-popover"
          arrow={false}
          placement="bottom"
          content={() => (
            <div className="bg-[#1D314C99] text-white p-2 rounded-[4px]">
              Points will be exchangeable for Manta Tokens in the future. The
              earnings from Points will be proportional to the length of time
              M-Tokens are held.
            </div>
          )}
          trigger="hover"
        >
          <img src={infoIcon} width={17} height={17} alt={'infoIcon'} />
        </Popover>
      </div>
      <div className="flex mt-2 flex-col items-end max-md:text-[24px]">
        {showData ? pointStatus?.data?.data?.total_scores || '0' : '--'}
      </div>
      <div className="mt-1 text-sm text-black-title opacity-70">
        Points for the current round start accumulating immediately after the
        previous snapshot. You need to stake at least $100 or an equivalent
        amount in tokens to claim the reward points.
      </div>
    </div>
  );
};

export const MTokenInfo = () => {
  const { userInfo } = useUserInfo();
  const { user_address } = userInfo;
  const navigate = useNavigate();
  const { data, mutate: mutateBatchMtokenInfo } = useBatchMtokenInfo();
  const { data: mData, mutate: mutateUserMTokenBalance } =
    useUserMTokenBalance();

  const { data: mtokenStateData } = useGetMtokenStake();
  const { addTokenToWalletWithCertainChain } = useAddToken();
  const usdPrices = useUsdPrices();
  const [mbtc, meth, musd] = data || [null, null, null];

  const {
    mBTC: _mBTC,
    mETH: _mETH,
    mUSD: _mUSD
  } = mData?.data || {
    mBTC: { amount: null },
    mETH: { amount: null },
    mUSD: { amount: null }
  };

  const mBTCAmount = mbtc?.balanceOfOriginData
    ? fromWei(mbtc?.balanceOfOriginData?.toString())?.toString()
    : '0';
  const mETHAmount = mbtc?.balanceOfOriginData
    ? fromWei(meth?.balanceOfOriginData?.toString())?.toString()
    : '0';
  const mUSDAmount = musd?.balanceOfOriginData
    ? fromWei(musd?.balanceOfOriginData?.toString())?.toString()
    : '0';

  const { details: mBTCDetail = [] } = _mBTC || {};
  const { details: mETHDetail = [] } = _mETH || {};
  const { details: mUSDDetail = [] } = _mUSD || {};
  const shiftFirstItem = (arr: { amount: string; protocol: string }[]) =>
    arr
      ?.filter(d => d?.protocol !== 'balance')
      .concat(arr?.filter(d => d?.protocol === 'balance'));

  const mBTCDetailFinal = shiftFirstItem(mBTCDetail);
  const mETHDetailFinal = shiftFirstItem(mETHDetail);
  const mUSDDetailFinal = shiftFirstItem(mUSDDetail);
  const mBTCHasProtocolBalance = !!mBTCDetail.filter(
    d => d?.protocol !== 'balance'
  ).length;
  const mETHHasProtocolBalance = !!mETHDetail.filter(
    d => d?.protocol !== 'balance'
  ).length;
  const mUSDHasProtocolBalance = !!mUSDDetail.filter(
    d => d?.protocol !== 'balance'
  ).length;

  const _mbtc = mBTCAmount ? toWei(mBTCAmount)?.toString() : '0';
  const _meth = mETHAmount ? toWei(mETHAmount)?.toString() : '0';
  const _musd = mUSDAmount ? toWei(mUSDAmount)?.toString() : '0';

  const mbtcStake = mtokenStateData?.[0]?.stakedAmount
    ? fromWei(mtokenStateData?.[0]?.stakedAmount)?.toString()
    : 0;
  const methStake = mtokenStateData?.[1]?.stakedAmount
    ? fromWei(mtokenStateData?.[1]?.stakedAmount || 0)?.toString()
    : 0;

  const musdStake = mtokenStateData?.[2]?.stakedAmount
    ? fromWei(mtokenStateData?.[2]?.stakedAmount || 0)?.toString()
    : 0;

  const showData = user_address && data;
  const values = useMemo(() => {
    if (!usdPrices || !data || !mtokenStateData) return null;
    try {
      const mbtcStakeAmount = new IBignumber(mbtcStake || 0)
        .times(usdPrices?.mBTC || '0')
        ?.toString();

      const methStakeAmount = new IBignumber(methStake || 0)
        .times(usdPrices?.mETH || '0')
        ?.toString();

      const musdStakeAmount = new IBignumber(musdStake || 0)
        .times(usdPrices?.mUSD || '0')
        ?.toString();

      const mbtcDetailsWithoutBalance = (mBTCDetail || []).filter(
        d => d.protocol !== 'balance'
      );

      const methDetailsWithoutBalance = (mETHDetail || []).filter(
        d => d.protocol !== 'balance'
      );

      const musdDetailsWithoutBalance = (mUSDDetail || []).filter(
        d => d.protocol !== 'balance'
      );

      const btcDetailsWithoutBalanceAmount = mbtcDetailsWithoutBalance.reduce(
        (acc, cur) => {
          const amount = new IBignumber(cur?.amount);
          return new IBignumber(amount)?.plus(acc)?.toNumber();
        },
        0
      );

      const ethDetailsWithoutBalanceAmount = methDetailsWithoutBalance.reduce(
        (acc, cur) => {
          const amount = new IBignumber(cur?.amount);
          return new IBignumber(amount)?.plus(acc)?.toNumber();
        },
        0
      );

      const musdDetailsWithoutBalanceAmount = musdDetailsWithoutBalance.reduce(
        (acc, cur) => {
          const amount = new IBignumber(cur?.amount);
          return new IBignumber(amount)?.plus(acc)?.toNumber();
        },
        0
      );

      const btcDetailsWithoutBalanceValue = new IBignumber(
        btcDetailsWithoutBalanceAmount || 0
      )
        .times(usdPrices?.mBTC || '0')
        ?.toString();

      const methDetailsWithoutBalanceValue = new IBignumber(
        ethDetailsWithoutBalanceAmount || 0
      )
        .times(usdPrices?.mETH || '0')
        ?.toString();

      const musdDetailsWithoutBalanceValue = new IBignumber(
        musdDetailsWithoutBalanceAmount || 0
      )
        .times(usdPrices?.mUSD || '0')
        ?.toString();

      const mbtcAmount = fromWei(_mbtc?.toString())
        .plus(mbtcStake)
        .plus(btcDetailsWithoutBalanceAmount)
        ?.toString();

      const methAmount = fromWei(_meth?.toString())
        .plus(Number.isNaN(methStake) ? 0 : methStake)
        .plus(
          Number.isNaN(ethDetailsWithoutBalanceAmount)
            ? 0
            : ethDetailsWithoutBalanceAmount
        )
        ?.toString();

      const musdAmount = fromWei(_musd?.toString())
        .plus(musdStake)
        .plus(musdDetailsWithoutBalanceAmount)
        ?.toString();

      const btcValue = fromWei(_mbtc?.toString())
        .times(usdPrices?.mBTC || 0)
        .plus(mbtcStakeAmount)
        .plus(btcDetailsWithoutBalanceValue);
      const ethValue = fromWei(_meth?.toString())
        .times(usdPrices?.mETH || 0)
        .plus(methStakeAmount)
        .plus(methDetailsWithoutBalanceValue);

      const usdValue = fromWei(_musd?.toString())
        .times(usdPrices?.mUSD || 0)
        .plus(musdStakeAmount)
        .plus(musdDetailsWithoutBalanceValue);

      const total = btcValue.plus(ethValue).plus(usdValue)?.toString();

      const totalFormat = numbroSafe(total).format({
        thousandSeparated: true,
        mantissa: DECIMAL_PLACES,
        trimMantissa: true
      });

      return {
        btcValue,
        ethValue,
        usdValue,
        mbtcAmount,
        methAmount,
        musdAmount,
        totalFormat
      };
    } catch (e) {
      console.log('get total usd value error: ', e);
    }
  }, [data, usdPrices, mData, mtokenStateData]);

  useEffect(() => {
    const refetch = async () => {
      if (user_address) {
        await mutateUserMTokenBalance();
        await mutateBatchMtokenInfo();
      }
    };
    return () => {
      refetch();
    };
  }, [user_address]);

  return (
    <>
      <div className="flex flex-col w-[260px] rounded-tr-2xl rounded-br-2xl bg-white/80 pr-6 py-6 border border-[#05D9A6]/60 text-black-title text-[20px] font-[400] max-md:w-full max-md:items-center max-md:mt-2 max-md:rounded-lg max-md:px-6 max-md:py-4">
        <div className="flex gap-2 items-center pl-12 max-md:pl-0">
          <p className="max-md:text-[20px]">Inventory</p>{' '}
          <Popover
            overlayClassName="point-popover"
            arrow={false}
            placement="bottom"
            content={() => (
              <div className="p-2">
                Assets deposited from the ETH/BSC arrives in about 30 minutes.
                The rest normally arrives within minutes.
              </div>
            )}
            trigger="hover"
          >
            <img src={infoIcon} width={17} height={17} alt={'infoIcon'} />
          </Popover>
        </div>
        <div className="text-xs mt-1 text-default-black/70 mb-2 pl-12 max-md:pl-0">
          Receive mTokens by depositing your crypto
        </div>

        <div className="flex flex-col items-end gap-2 text-[16px] max-md:items-center">
          <p className="text-right">
            Total Value: ≈{' '}
            {values?.totalFormat ? `$ ${values?.totalFormat}` : '--'}
          </p>
          <p className="flex items-center">
            {mBTCHasProtocolBalance || new IBignumber(mbtcStake).gt(0) ? (
              <Popover
                overlayClassName="point-popover"
                arrow={false}
                placement="right"
                content={() => (
                  <MtokenDetail
                    details={mBTCDetailFinal}
                    token={mBTC}
                    stake={mbtcStake}
                    mtokenBalance={mBTCAmount}
                  />
                )}
                trigger="hover"
              >
                <span className="underline">
                  {values?.mbtcAmount
                    ? numbroSafe(values?.mbtcAmount || 0).format({
                        thousandSeparated: true,
                        mantissa: DECIMAL_PLACES,
                        trimMantissa: true
                      })
                    : '--'}{' '}
                  MBTC
                </span>
              </Popover>
            ) : (
              <>
                {showData
                  ? values?.mbtcAmount
                    ? numbroSafe(values?.mbtcAmount).format({
                        thousandSeparated: true,
                        mantissa: DECIMAL_PLACES,
                        trimMantissa: true
                      })
                    : '--'
                  : '--'}{' '}
                MBTC
              </>
            )}
            <img
              src={metamaskIcon}
              className="ml-2 w-5 h-5 hover:opacity-80 hover:cursor-pointer"
              onClick={() =>
                addTokenToWalletWithCertainChain(MANTA_PACIFIC_CHAIN.id, {
                  symbol: mBTC.name,
                  decimals: mBTC.decimals,
                  address: mBTC.address
                })
              }
            />
          </p>
          <p className="flex items-center">
            {mETHHasProtocolBalance || new IBignumber(methStake).gt(0) ? (
              <Popover
                overlayClassName="point-popover"
                arrow={false}
                placement="right"
                content={() => (
                  <MtokenDetail
                    details={mETHDetailFinal}
                    token={mETH}
                    stake={methStake}
                    mtokenBalance={mETHAmount}
                  />
                )}
                trigger="hover"
              >
                <span className="underline">
                  {values?.methAmount
                    ? numbroSafe(values?.methAmount).format({
                        thousandSeparated: true,
                        mantissa: DECIMAL_PLACES,
                        trimMantissa: true
                      })
                    : '--'}{' '}
                  METH
                </span>
              </Popover>
            ) : (
              <>
                {showData
                  ? values?.methAmount
                    ? numbroSafe(values?.methAmount).format({
                        thousandSeparated: true,
                        mantissa: DECIMAL_PLACES,
                        trimMantissa: true
                      })
                    : '--'
                  : '--'}{' '}
                METH
              </>
            )}
            <img
              src={metamaskIcon}
              className="ml-2 w-5 h-5 hover:opacity-80 hover:cursor-pointer"
              onClick={() =>
                addTokenToWalletWithCertainChain(MANTA_PACIFIC_CHAIN.id, {
                  symbol: mETH.name,
                  decimals: mETH.decimals,
                  address: mETH.address
                })
              }
            />
          </p>
          <p className="flex items-center">
            {mUSDHasProtocolBalance || new IBignumber(musdStake).gt(0) ? (
              <Popover
                overlayClassName="point-popover"
                arrow={false}
                placement="right"
                content={() => (
                  <MtokenDetail
                    details={mUSDDetailFinal}
                    token={mUSD}
                    stake={musdStake}
                    mtokenBalance={mUSDAmount}
                  />
                )}
                trigger="hover"
              >
                <span className="underline">
                  {values?.usdValue
                    ? numbroSafe(values?.usdValue).format({
                        thousandSeparated: true,
                        mantissa: DECIMAL_PLACES,
                        trimMantissa: true
                      })
                    : '--'}{' '}
                  MUSD
                </span>
              </Popover>
            ) : (
              <>
                {showData
                  ? values?.musdAmount
                    ? numbroSafe(values?.musdAmount || 0).format({
                        thousandSeparated: true,
                        mantissa: DECIMAL_PLACES,
                        trimMantissa: true
                      })
                    : '--'
                  : '--'}{' '}
                MUSD
              </>
            )}
            <img
              src={metamaskIcon}
              className="ml-2 w-5 h-5 hover:opacity-80 hover:cursor-pointer"
              onClick={() =>
                addTokenToWalletWithCertainChain(MANTA_PACIFIC_CHAIN.id, {
                  symbol: mUSD.name,
                  decimals: mUSD.decimals,
                  address: mUSD.address
                })
              }
            />
          </p>
          <span className="text-end text-xs text-black-title/70 font-normal ">
            Hourly Update
          </span>
          <div className="p-2 flex flex-col gap-1  text-black-title/70 text-[14px] font-[400] whitespace-nowrap max-md:w-full border-t border-b border-dashed border-green">
            <div className="flex flex-col items-end gap-1 max-md:items-center">
              <p>1 MBTC = {showData ? mbtc?.rewardMultiplier : '--'} BTC</p>
              <p>1 METH = {showData ? meth?.rewardMultiplier : '--'} ETH</p>
              <p>
                1 MUSD = {showData ? musd?.rewardMultiplier : '--'} USDT/USDC
              </p>
            </div>
            <p className="text-[12px] font-[400] text-black-title/70 text-right italic max-md:text-center">
              Updated every day at 5:00 AM UTC
            </p>
          </div>
          <div
            onClick={() => {
              navigate('/dashboard/history');
            }}
            className="flex text-[14px] items-center gap-2 text-black-title hover:opacity-80 hover:cursor-pointer"
          >
            <span>Deposit History</span>
            <img src={arrowBlack} />
          </div>
        </div>
      </div>
    </>
  );
};

const SubNav = () => {
  const { pathname } = useLocation();
  const { data: earnMoreData } = useEarnMoreStatus();
  const earnMorelist = earnMoreData?.data?.project_list || [];
  const hasNew = earnMorelist.filter(e => e?.is_new).length > 0;

  const isStakeSelected =
    pathname === '/dashboard' || pathname === '/dashboard/stake';
  const isPointsSelected = pathname === '/dashboard/points';
  const isEarnMoreSelected = pathname === '/dashboard/earn_more';
  const isStakeMTokenSelected = pathname === '/dashboard/m_token';
  return (
    <div className=" flex flex-col gap-4 ">
      <div className="flex flex-col gap-4 w-[260px] rounded-tr-2xl rounded-br-2xl bg-white/80 pl-12 pr-[18px] py-6 border border-[#05D9A6]/60">
        <Link
          className={classNames(
            'h-[29px] leading-6 text-xl flex items-center gap-2 font-[300]',
            {
              'text-green': isStakeSelected
            }
          )}
          to="stake"
        >
          {isStakeSelected && <Dot />}Deposit
        </Link>
        <Link
          className={classNames(
            'h-[29px] leading-6 text-xl flex items-center gap-2 font-[300]',
            {
              'text-green': isStakeMTokenSelected
            }
          )}
          to="m_token"
        >
          {isStakeMTokenSelected && <Dot />}Stake M-Tokens
        </Link>
        <Link
          className={classNames(
            'h-[29px] leading-6 text-xl flex items-center gap-2 font-[300]',
            {
              'text-green': isPointsSelected
            }
          )}
          to="points"
        >
          {isPointsSelected && <Dot />}Points
        </Link>
        <Link
          className={classNames(
            'h-[29px] text-nowrap leading-1 text-xl flex items-center gap-2 font-[300]',
            {
              'text-green': isEarnMoreSelected
            }
          )}
          to="earn_more"
        >
          {isEarnMoreSelected && <Dot />}
          Earn More
          {hasNew && <NewButton />}
        </Link>
      </div>
      <MTokenInfo />
      <MyPoint />
      <UserAgreement />
    </div>
  );
};

export default SubNav;
