import { useEffect, useMemo, useState } from 'react';
// import { useConfigContext } from '@/context/ConfigContext';
// import { Erc20__factory } from '@/contracts/interface';
import BigNumber from 'bignumber.js';
import { Contract, ethers } from 'ethers';
import { useAccount } from 'wagmi';
import { fromTokenDecimals, fromWei } from '@/utils/format';
import mTokenABI from '@/abis/MToken.json';
import { useEthersSigner } from './useEthersSigner';
import useTransaction from './useTransaction';

export interface UseMTokenProps {
  tokenAddress: string | undefined;
  approveAddress?: string;
  approveAmount?: string;
}

function Index({
  tokenAddress,
  approveAddress,
  approveAmount = ethers.constants.MaxUint256?.toString()
}: UseMTokenProps) {
  const signer = useEthersSigner();
  const { address } = useAccount();

  const [allowanceAmount, setAllowanceAmount] = useState<string>(); // 授权额度
  const [isBalanceLoading, setIsBalanceLoading] = useState(false);

  const [balance, setBalance] = useState<string>();

  const mTokenAbi = useMemo(() => {
    if (!signer || !tokenAddress) return null;

    return new Contract(tokenAddress, mTokenABI, signer);
  }, [signer, tokenAddress]);

  // 授权
  const approveState = useTransaction(mTokenAbi?.approve, {
    wait: true,
    args: [approveAddress as string, ethers.constants.MaxUint256]
  });

  // 获取授权额度
  const getAllowance = async () => {
    if (!address || !approveAddress) return;
    const res = await mTokenAbi?.allowance(address, approveAddress);
    setAllowanceAmount(res ? fromWei(res?.toString())?.toString() : '0');
  };

  const isOverAllowance = useMemo(() => {
    if (!allowanceAmount) return undefined;

    return (
      new BigNumber(allowanceAmount).gte(approveAmount) &&
      new BigNumber(allowanceAmount).gt('0')
    );
  }, [allowanceAmount, approveAmount]);

  const isAllowanceLoading = isOverAllowance === undefined;

  const getBalance = async () => {
    try {
      setIsBalanceLoading(true);
      const balance = await mTokenAbi?.balanceOf(address as string);

      const decimals = await mTokenAbi?.decimals();
      const balanceStr = balance
        ? fromTokenDecimals(balance?.toString(), decimals as number)?.toString()
        : '0';

      setBalance(balanceStr);
    } finally {
      setIsBalanceLoading(false);
    }
  };

  useEffect(() => {
    getAllowance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [approveState.result]);

  useEffect(() => {
    if (!address || !mTokenAbi || !approveAddress) return;
    if (approveAddress === ethers.constants.AddressZero) return;
    getAllowance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [approveAddress, address, mTokenAbi]);

  useEffect(() => {
    if (!address || !mTokenAbi || !tokenAddress) return;

    getBalance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mTokenAbi, address, tokenAddress]);

  return {
    isOverAllowance,
    isAllowanceLoading,
    approveState,
    allowanceAmount,
    balance,
    isBalanceLoading,
    getBalance
  };
}
export default Index;
