import { Table, TableColumnsType } from 'antd';
import { formatUnits } from 'ethers/lib/utils';
import { Chain } from 'viem';
import { BigNumber, Contract, ethers } from 'ethers';
import { useEffect, useState } from 'react';
import Decimal from 'decimal.js';
import { useAccount, useFeeData } from 'wagmi';
import { KeyedMutator } from 'swr';
import dayjs from '@/utils/dayjsWithExtends';
import { UnstakeStatus } from '@/pages/Stake/UnstakeContent/components/UnstakePending';
import config from '@/config';
import { roundDownNumString } from '@/utils/formatNumber';
import {
  bnbChain,
  ethChain,
  layerzeroDesIdMap,
  unstakeContract
} from '@/pages/Stake/UnstakeContent';
import mTokenAbi from '@/abis/MToken.json';
import { useEthersSigner } from '@/hooks/useEthersSigner';
import ClaimButton from '../ClaimButton';
import useUnstakeHistory, {
  HistoryResult,
  UnstakeHistoryItem
} from '../../hooks/useUnstakeHistory';

export interface UnstakeHistoryContentProps {
  historyList: Array<UnstakeHistoryItem>;
}

const {
  mBTC,
  mETH,
  mUSD,
  supportedChain,
  ETH_USDC,
  ETH_USDT,
  ETH_WBTC,
  BNB_BTCB,
  BNB_USDC,
  BNB_USDT,
  MANTA_PACIFIC_CHAIN
} = config;

export type Token = typeof mBTC;

const findTargetChain = (desId: string) => {
  return (supportedChain as Chain[]).find(
    (chain: Chain) => layerzeroDesIdMap?.[chain.id]?.toString() === desId
  );
};

const findTargetToken = (
  chainId: number | undefined,
  targetAddress: string
) => {
  if (chainId == null) {
    return;
  }
  if (chainId === ethChain.id) {
    switch (targetAddress.toLocaleLowerCase()) {
      case ETH_USDC.address.toLocaleLowerCase(): {
        return ETH_USDC;
      }
      case ETH_USDT.address.toLocaleLowerCase(): {
        return ETH_USDT;
      }
      case ETH_WBTC.address.toLocaleLowerCase(): {
        return ETH_WBTC;
      }
      case ethers.constants.AddressZero: {
        return {
          address: targetAddress,
          decimals: 18,
          name: 'ETH'
        };
      }

      default:
        return;
    }
  } else if (chainId === bnbChain.id) {
    switch (targetAddress.toLocaleLowerCase()) {
      case BNB_USDC.address.toLocaleLowerCase(): {
        return BNB_USDC;
      }
      case BNB_USDT.address.toLocaleLowerCase(): {
        return BNB_USDT;
      }
      case BNB_BTCB.address.toLocaleLowerCase(): {
        return BNB_BTCB;
      }
      default:
        return;
    }
  }
  return;
};

const findMToken = (mTokenAddress: string) => {
  switch (mTokenAddress.toLocaleLowerCase()) {
    case mBTC.address.toLocaleLowerCase():
      return mBTC;
    case mETH.address.toLocaleLowerCase():
      return mETH;
    case mUSD.address.toLocaleLowerCase():
      return mUSD;
    default:
      return;
  }
};

export type FinalHistoryItem = UnstakeHistoryItem & {
  targetChain?: Chain;
  targetToken?: Token;
  ratio?: number;
  mTokenObj?: Token;
  mTokenAmountStr?: string;
  gasFee: BigNumber;
  layerzeroFee: BigNumber;
  updateHistoryList: KeyedMutator<HistoryResult>;
  key: string;
};

const columns: TableColumnsType<FinalHistoryItem> = [
  {
    align: 'center',
    title: (
      <div className="text-sm font-normal text-black-title/60">
        Request Time
      </div>
    ),
    dataIndex: 'date',
    key: 'requestTime',
    width: 100,
    render: (text, record) => {
      return dayjs(Number(record.requestWithdrawTimestamp) * 1000).format(
        'MMM D YYYY, HH:mm:ss'
      );
    }
  },
  {
    align: 'center',
    title: (
      <div className="text-sm font-normal text-black-title/60">
        Requested Amount
      </div>
    ),
    dataIndex: 'requestAmount',
    key: 'requestAmount',
    width: 100,
    render: (text, record) => {
      return record?.mTokenObj
        ? `${record?.mTokenAmountStr} ${record?.mTokenObj?.name}`
        : '--';
    }
  },
  {
    align: 'center',
    title: (
      <div className="text-sm font-normal text-black-title/60">
        Destination Chain
      </div>
    ),
    dataIndex: 'desChain',
    key: 'destinationChain',
    width: 100,
    render: (text, record) => {
      return record?.targetChain?.name ?? '--';
    }
  },
  {
    align: 'center',
    title: (
      <div className="text-sm font-normal text-black-title/60">
        Est. Receive Amount
      </div>
    ),
    dataIndex: 'estReceiveAmount',
    key: 'receiveAmount',
    width: 100,
    render: (text, record) => {
      const ratio =
        record.appliedRatio != null
          ? formatUnits(record.appliedRatio, 18)
          : record.ratio;
      if (record?.mTokenAmountStr && ratio && record?.targetToken) {
        return `${new Decimal(ratio)
          .mul(record?.mTokenAmountStr ?? 0)
          .toDecimalPlaces(5, Decimal.ROUND_DOWN)
          ?.toString()} ${record.targetToken.name}`;
      }

      return '--';
    }
  },
  {
    align: 'center',
    title: (
      <div className="text-sm font-normal text-black-title/60">
        Estimated Time to Claim
      </div>
    ),
    dataIndex: 'estTimeToClaim',
    key: 'estimateTime',
    width: 100,
    render: (text, record) => {
      const claimDay = dayjs(
        Number(record.requestWithdrawTimestamp) * 1000
      ).add(8, 'days');
      const curDay = dayjs();
      if (
        record.status === UnstakeStatus.claimed ||
        record.status === UnstakeStatus.finalized
      ) {
        return '0 day';
      }
      return dayjs.duration(claimDay.diff(curDay, 'days'), 'days').humanize();
    }
  },
  {
    align: 'center',
    title: (
      <div className="text-sm font-normal text-black-title/60">Status</div>
    ),
    dataIndex: 'status',
    key: 'status',
    width: 100,
    render: (text, record) => {
      if (record.status === UnstakeStatus.pending) {
        return (
          <div className="flex items-center text-[#FE8311] text-sm font-medium">
            <span className="w-2 h-2 rounded-full bg-[#FE8311] mr-2 flex-shrink-0"></span>
            <span>Requested withdraw</span>
          </div>
        );
      } else if (record.status === UnstakeStatus.finalized) {
        return <ClaimButton {...record} key={record.key} />;
      } else if (record.status === UnstakeStatus.claimed) {
        return (
          <div>
            <div className="flex items-center text-[#00D27A] text-sm font-medium">
              <span className="w-2 h-2 rounded-full bg-[#00D27A] mr-2 flex-shrink-0"></span>
              <span>Claimed</span>
            </div>
            <p className="text-xs text-black-title/60 text-left">
              Estimated arrival time for {record?.targetToken?.name}: 2 minutes
            </p>
          </div>
        );
      }

      return 'Unknown status';
    }
  }
];

function UnstakeHistoryContent({
  historyList,
  mTokenAddress
}: {
  historyList: UnstakeHistoryItem[];
  mTokenAddress: string;
}) {
  const [finalList, setFinalList] = useState<FinalHistoryItem[]>([]);
  const [isLoading, toggleIsLoading] = useState(false);

  const { address } = useAccount();
  const { data: feeData } = useFeeData();
  const { updateHistoryList, historyValidating } = useUnstakeHistory();

  const signer = useEthersSigner();

  useEffect(() => {
    const getFinalList = async () => {
      toggleIsLoading(true);
      const filterListByMToken = historyList.filter(
        history =>
          history.mToken.toLocaleLowerCase() ===
          mTokenAddress.toLocaleLowerCase()
      );
      // get mToken's ratio
      let formatRatio = 0;
      const firstPendingHistory = historyList?.find(
        history => history.status === UnstakeStatus.pending
      );
      // only get ratio from pending history
      if (firstPendingHistory) {
        const tokenContract = new Contract(
          firstPendingHistory.mToken,
          mTokenAbi,
          new ethers.providers.StaticJsonRpcProvider(
            MANTA_PACIFIC_CHAIN.rpcUrls.default.http[0]
          )
        );
        const ratio = await tokenContract.rewardMultiplier();
        formatRatio = Number(ratio ? formatUnits(ratio, 18) : ''); // Using static 18
      }
      // get layerzero fee and gas fee for claim
      const readyToClaimedItem = filterListByMToken.find(history => {
        return history.status === UnstakeStatus.finalized;
      });
      let layerzeroFee = BigNumber.from(0);
      let gasFee = BigNumber.from(0);
      if (readyToClaimedItem) {
        const claimParams = {
          mToken: readyToClaimedItem.mToken,
          desEndId: readyToClaimedItem.desEndId,
          refundToken: readyToClaimedItem.refundToken,
          indexInQueue: readyToClaimedItem.indexInQueue,
          checkpointIndex: readyToClaimedItem.checkpointIndex,
          msgValueRefundReceiver: address,
          desTokenRefundReceiver: address
        };
        try {
          layerzeroFee = await unstakeContract.quote(claimParams);
        } catch (e) {
          console.log('get claim gas fee error: ', e);
        }

        try {
          if (signer) {
            const gasAmount = await unstakeContract
              ?.connect(signer)
              ?.estimateGas?.claim?.(claimParams, { value: layerzeroFee });
            console.log('gasAmount', gasAmount);

            const gasPrice = feeData?.gasPrice;
            gasFee =
              gasPrice && gasAmount
                ? gasAmount?.mul(gasPrice)
                : BigNumber.from('0');
          }
        } catch (e) {
          console.log('get claim gas fee error: ', e);
        }
      }

      const list = filterListByMToken.map(history => {
        const targetChain = findTargetChain(history.desEndId);
        const mTokenObj = findMToken(history.mToken);
        return {
          ...history,
          targetChain: findTargetChain(history.desEndId),
          targetToken: findTargetToken(targetChain?.id, history.refundToken),
          ratio: formatRatio,
          mTokenObj,
          mTokenAmountStr: roundDownNumString(
            formatUnits(history.mTokenAmount, mTokenObj?.decimals),
            5
          ),
          gasFee,
          layerzeroFee,
          updateHistoryList,
          key: history.id
        } as FinalHistoryItem;
      });
      setFinalList(list);
      toggleIsLoading(false);
    };
    getFinalList();
  }, [
    address,
    feeData?.gasPrice,
    historyList,
    updateHistoryList,
    signer,
    mTokenAddress
  ]);

  return (
    <Table
      virtual
      columns={columns}
      dataSource={finalList}
      bordered
      size="middle"
      loading={isLoading || historyValidating}
      scroll={{ x: 1158, y: 400 }}
      className="history-page"
      pagination={false}
      showHeader={!!finalList?.length}
      rowClassName="text-black-title"
    />
  );
}

export default UnstakeHistoryContent;
