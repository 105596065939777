import { useState } from 'react';
import { useAccount } from 'wagmi';
import { BigNumber, Contract } from 'ethers';
import { Chain } from 'viem';

import ConnectWallet from '@/components/ConnectWallet';
import useUserInfo from '@/store/useUserInfo';
import LoadingIcon from '@/components/Loading';
import Faq from './Faq';
import StakeContent from './StakeContent';
import { UnstakeContent } from './UnstakeContent';

export type StakeChain = Chain & {
  value: number;
  label: string;
};

export type StakeMToken = {
  tokenContract: Contract;
  name: string;
  address: string;
  decimals: number;
};
export type StakeToken = {
  mToken: StakeMToken;
  address: string; // stake token's address
  decimals: number; // token's decimals
  stakePaused: boolean; // whether stake is paused
  minDepositAmount: BigNumber; // min amount to deposit
  name: string;
  value: string;
  label: string;
  isNativeToken: boolean;
  tokenContract: Contract;
  fee?: BigNumber; // just used for manta pacific chain
  conversionRate?: BigNumber; // decimal 18
};

function Stake() {
  const [isStakeTab, toggleIsStakeTab] = useState(true);

  const { address } = useAccount();

  const { userInfo, isLoading } = useUserInfo();
  const { user_address } = userInfo;

  if (isLoading) {
    return (
      <div className="flex items-center h-[calc(100vh-108px)] justify-center">
        <LoadingIcon isDark />
      </div>
    );
  }

  if (!user_address || !address) {
    return <ConnectWallet />;
  }

  return (
    <div className="flex flex-col justify-center items-center mt-4 w-[600px] mx-auto text-default max-md:w-full">
      <div className="mb-4 border border-green/60 rounded-[50px] bg-white/80 p-1 flex items-center justify-around max-md:w-full">
        <button
          className={`uppercase rounded-[20px] w-[240px] py-2 max-md:flex-1 ${
            isStakeTab
              ? 'bg-green text-white font-medium'
              : 'bg-transparent text-default/70'
          }`}
          onClick={() => toggleIsStakeTab(true)}
        >
          Deposit
        </button>
        <button
          className={`uppercase rounded-[20px] w-[240px] py-2 max-md:flex-1 ${
            !isStakeTab
              ? 'bg-green text-white font-medium'
              : 'bg-transparent text-default/70'
          }`}
          onClick={() => toggleIsStakeTab(false)}
        >
          Withdraw
        </button>
      </div>
      {isStakeTab ? (
        <>
          <StakeContent /> <Faq />
        </>
      ) : (
        <UnstakeContent />
      )}
    </div>
  );
}
export default Stake;
