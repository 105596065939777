import { BigNumber, Contract, ethers } from 'ethers';
import useSWR from 'swr';
import MToken from '@/abis/MToken.json';
import config from '@/config';
import { numbroSafe } from '@/utils/format';
import useUserInfo from './useUserInfo';

const { MANTA_PACIFIC_CHAIN } = config;
export const DECIMAL_PLACES = 5;

function useBatchMtokenInfo() {
  const {
    userInfo: { user_address }
  } = useUserInfo();

  const state = useSWR(
    user_address && config ? ['/batchMtokenInfo', user_address] : null,
    async () => {
      return batchMtokenInfo();
    },
    {
      revalidateOnFocus: false,
      refreshInterval: 5 * 60 * 1000
    }
  );

  const getMTokenInfo = async (tokenAddress: string) => {
    const contract = new Contract(
      tokenAddress,
      MToken,
      new ethers.providers.StaticJsonRpcProvider(
        MANTA_PACIFIC_CHAIN?.rpcUrls?.default.http[0]
      ) // mToken only exist in manta paicifc chain
    );
    const rewardMultiplier = (await contract.rewardMultiplier()) as BigNumber;
    const balanceOf = (await contract.balanceOf(user_address)) as BigNumber;
    return {
      rewardMultiplierOriginData: rewardMultiplier,
      balanceOfOriginData: balanceOf,
      rewardMultiplier: numbroSafe(
        ethers.utils.formatUnits(rewardMultiplier).toString()
      ).format({
        thousandSeparated: true,
        mantissa: DECIMAL_PLACES,
        trimMantissa: true
      }),
      balanceOf: numbroSafe(
        ethers.utils.formatUnits(balanceOf).toString()
      ).format({
        thousandSeparated: true,
        mantissa: DECIMAL_PLACES,
        trimMantissa: true
      })
    };
  };

  const batchMtokenInfo = async () => {
    try {
      const res = await Promise.all([
        getMTokenInfo(config.mBTC.address),
        getMTokenInfo(config.mETH.address),
        getMTokenInfo(config.mUSD.address)
      ]);

      return res;
    } catch (error) {
      console.log('error', error);
    }
  };

  return state;
}
export default useBatchMtokenInfo;
