import { useLocation } from 'react-router-dom';
import ErrorBoundary from '@/components/ErrorBoundary';
import SubNav from '@/components/SubNav';
import useNotificationFromUrl from '@/hooks/useNotificationFromUrl';
import EarnMore from '../EarnMore';
import Invite from '../Invite';
import Points from '../Points';
import Stake from '../Stake';
import StakeMToken from '../StakeMToken';
import StakingHistory from '../StakingHistory';

// import '../../assets/h5-bg-dashboard.png'

function Content() {
  const { pathname } = useLocation();
  if (pathname.includes('m_token')) {
    return (
      <ErrorBoundary name="StakeMToken">
        <StakeMToken />
      </ErrorBoundary>
    );
  }
  if (pathname.includes('stake')) {
    return (
      <ErrorBoundary name="Stake">
        <Stake />
      </ErrorBoundary>
    );
  }
  if (pathname.includes('history')) {
    return (
      <ErrorBoundary name="StakingHistory">
        <StakingHistory />
      </ErrorBoundary>
    );
  }
  if (pathname.includes('points')) {
    return (
      <ErrorBoundary name="Points">
        <Points />
      </ErrorBoundary>
    );
  }
  if (pathname.includes('earn_more')) {
    return (
      <ErrorBoundary name="EarnMore">
        <EarnMore />
      </ErrorBoundary>
    );
  }
  if (pathname.includes('invites')) {
    return (
      <ErrorBoundary name="Invite">
        <Invite />
      </ErrorBoundary>
    );
  }
  return (
    <ErrorBoundary name="Stake">
      <Stake />
    </ErrorBoundary>
  );
}

function Dashboard() {
  useNotificationFromUrl('message');

  return (
    <>
      {/** PC */}
      <div className="flex items-start pr-12 pt-6 gap-4 dashboard-bg max-md:hidden">
        <ErrorBoundary name="SubNav">
          <SubNav />
        </ErrorBoundary>
        <div className="flex-1">
          <Content />
        </div>
      </div>
      {/* H5 */}
      <div className="flex min-h-full flex-col px-6 py-4 gap-2 dashboard-bg  md:hidden">
        <Content />
      </div>
    </>
  );
}

export default Dashboard;
